//const ENV = 'DEV';
//const ENV = "QA";
//const ENV = 'PRE_PROD';
const ENV = "PROD";
//const ENV = 'NT';

export const ALL_CONFIG = {
  DEV_BASE_URL: "https://apidev.accountabilitytech.com/",
  DEV_GOOGLE_TRACKING_ID: "G-1Z5JKXZY6G",
  DEV_GOOGLE_PLACES_URL:
    "https://maps.googleapis.com/maps/api/place/autocomplete",
  DEV_GOOGLE_PLACES_KEY: "AIzaSyDNv1zav4uZMEG3mnmbCWXhY_WcV-dGQpk",
  DEV_GOOGLE_PLACES_BASE_URL: "https://maps.googleapis.com/maps/api/js",
  DEV_LIVE_CHAT_LICENSE: "13875492",
  DEV_GTM_ID: "GTM-5BV8HZ2",
  DEV_INVENTORY:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/inventory",
  DEV_EXPENSES:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/expenses",
  DEV_INCOME:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/income",
  DEV_ACTIVITY:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/activity-log",
  DEV_MEDICAL:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/medical",
  DEV_DOCUMENT:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/documents",

  QA_BASE_URL: "https://apiqa.accountabilitytech.com/",
  QA_GOOGLE_TRACKING_ID: "G-115BMK31Z4",
  QA_GOOGLE_PLACES_URL:
    "https://maps.googleapis.com/maps/api/place/autocomplete",
  QA_GOOGLE_PLACES_KEY: "AIzaSyDNv1zav4uZMEG3mnmbCWXhY_WcV-dGQpk",
  QA_GOOGLE_PLACES_BASE_URL: "https://maps.googleapis.com/maps/api/js",
  QA_LIVE_CHAT_LICENSE: "13875492",
  QA_GTM_ID: "GTM-MFDKP5Z",
  QA_INVENTORY:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/inventory",
  QA_EXPENSES:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/expenses",
  QA_INCOME:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/income",
  QA_ACTIVITY:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/activity-log",
  QA_MEDICAL:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/medical",
  QA_DOCUMENT:
    "https://qa.advocord.com/learning-center/how-to-use-advocord/documents",

  PRE_PROD_BASE_URL:
    "https://9jga81mqyf.execute-api.us-east-1.amazonaws.com/pre-prod/",
  PRE_PROD_GOOGLE_TRACKING_ID: "G-115BMK31Z4",
  PRE_PROD_GOOGLE_PLACES_URL:
    "https://maps.googleapis.com/maps/api/place/autocomplete",
  PRE_PROD_GOOGLE_PLACES_KEY: "AIzaSyDNv1zav4uZMEG3mnmbCWXhY_WcV-dGQpk",
  PRE_PROD_GOOGLE_PLACES_BASE_URL: "https://maps.googleapis.com/maps/api/js",

  NT_BASE_URL: "https://i0erw78yfc.execute-api.us-east-1.amazonaws.com/neeraj/",
  NT_GOOGLE_TRACKING_ID: "G-115BMK31Z4",
  NT_GOOGLE_PLACES_URL:
    "https://maps.googleapis.com/maps/api/place/autocomplete",
  NT_GOOGLE_PLACES_KEY: "AIzaSyDNv1zav4uZMEG3mnmbCWXhY_WcV-dGQpk",
  NT_GOOGLE_PLACES_BASE_URL: "https://maps.googleapis.com/maps/api/js",

  PROD_BASE_URL: "https://api.advocord.com/",
  PROD_GOOGLE_TRACKING_ID: "G-115BMK31Z4",
  PROD_GOOGLE_TRACKING_ID2: "AW-10879631349/lXV-CML3xbADEPX_58Mo",
  PROD_GOOGLE_PLACES_URL:
    "https://maps.googleapis.com/maps/api/place/autocomplete",
  PROD_GOOGLE_PLACES_KEY: "AIzaSyDNv1zav4uZMEG3mnmbCWXhY_WcV-dGQpk",
  PROD_GOOGLE_PLACES_BASE_URL: "https://maps.googleapis.com/maps/api/js",
  PROD_LIVE_CHAT_LICENSE: "13875492",
  PROD_GTM_ID: "GTM-54QLPXQ",
  // 'PROD_INVENTORY':'https://qa.advocord.com/learning-center/how-to-use-advocord/inventory',
  // 'PROD_EXPENSES':'https://qa.advocord.com/learning-center/how-to-use-advocord/expenses',
  // 'PROD_INCOME':'https://qa.advocord.com/learning-center/how-to-use-advocord/income',
  // 'PROD_ACTIVITY':'https://qa.advocord.com/learning-center/how-to-use-advocord/activity-log',
  // 'PROD_MEDICAL':'https://qa.advocord.com/learning-center/how-to-use-advocord/medical',
  // 'PROD_DOCUMENT':'https://qa.advocord.com/learning-center/how-to-use-advocord/documents',
  PROD_INVENTORY:
    "https://advocord.com/learning-center/how-to-use-advocord/inventory",
  PROD_EXPENSES:
    "https://advocord.com/learning-center/how-to-use-advocord/expenses",
  PROD_INCOME:
    "https://advocord.com/learning-center/how-to-use-advocord/income",
  PROD_ACTIVITY:
    "https://advocord.com/learning-center/how-to-use-advocord/activity-log",
  PROD_MEDICAL:
    "https://advocord.com/learning-center/how-to-use-advocord/medical",
  PROD_DOCUMENT:
    "https://advocord.com/learning-center/how-to-use-advocord/documents",
};

export const CONFIG = {
  baseUrl: ALL_CONFIG[ENV + "_BASE_URL"],
  GOOGLE_TRACKING_ID: ALL_CONFIG[ENV + "_GOOGLE_TRACKING_ID"],
  GOOGLE_TRACKING_ID2: ALL_CONFIG[ENV + "_GOOGLE_TRACKING_ID2"],
  GOOGLE_PLACES_URL: ALL_CONFIG[ENV + "_GOOGLE_PLACES_URL"],
  GOOGLE_PLACES_KEY: ALL_CONFIG[ENV + "_GOOGLE_PLACES_KEY"],
  GOOGLE_PLACES_BASE_URL: ALL_CONFIG[ENV + "_GOOGLE_PLACES_BASE_URL"],
  LIVE_CHAT_LICENSE: ALL_CONFIG[ENV + "_LIVE_CHAT_LICENSE"],
  GTM_ID: ALL_CONFIG[ENV + "_GTM_ID"],
  INVENTORY: ALL_CONFIG[ENV + "_INVENTORY"],
  EXPENSES: ALL_CONFIG[ENV + "_EXPENSES"],
  INCOME: ALL_CONFIG[ENV + "_INCOME"],
  ACTIVITY: ALL_CONFIG[ENV + "_ACTIVITY"],
  MEDICAL: ALL_CONFIG[ENV + "_MEDICAL"],
  DOCUMENT: ALL_CONFIG[ENV + "_DOCUMENT"],
};

export default CONFIG;
