import React, { useState, useEffect } from "react";
import A12Y_CONST from "../common/a12yConst";

function PopUpWelcomeAdvocord(props) {
  let isSurvey = false;
  const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
  const [selectedSurvey, setSelectedSurvey] = useState({});
  const [selectedRole, setSelectedRole] = useState({});
  const [isSelectedRole, setIsSelectedRole] = useState(false);
  const [isSurveySection, setIsSurveySection] = useState(false);
  const [surveyList, setSurveyList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [referralCourt, setReferralCourt] = useState("");

  useEffect(() => {
    setSurveyList([
      { id: 1, title: A12Y_CONST.TEXT.REFERRAL_COURTS, isChecked: false },
      {
        id: 2,
        title: A12Y_CONST.TEXT.REFERRAL_FROM_PROFESSIONAL,
        isChecked: false,
      },
      { id: 3, title: A12Y_CONST.TEXT.ANOTHER_ADVOCORD, isChecked: false },
      { id: 4, title: A12Y_CONST.TEXT.GOOGLE, isChecked: false },
      {
        id: 5,
        title: A12Y_CONST.TEXT.BANK_FINANCIAL_INSTITUTE,
        isChecked: false,
      },
      //   {id:6, title:A12Y_CONST.TEXT.TCARE, isChecked:false},
      { id: 7, title: A12Y_CONST.TEXT.TWITTER, isChecked: false },
      { id: 8, title: A12Y_CONST.TEXT.LINKEDIN, isChecked: false },
      { id: 9, title: A12Y_CONST.TEXT.OTHER_SURVEY, isChecked: false },
    ]);
    setRoleList([
      { id: 1, title: A12Y_CONST.TEXT.GUARDIAN_CONSERVATOR, isChecked: false },
      {
        id: 2,
        title: A12Y_CONST.TEXT.AGENT_UNDER_POWER_OF_ATTORNEY,
        isChecked: false,
      },
      { id: 3, title: A12Y_CONST.TEXT.REPRESENTATIVE_PAYEE, isChecked: false },
      { id: 4, title: A12Y_CONST.TEXT.OTHER_LABEL, isChecked: false },
    ]);
  }, []);

  useEffect(() => {
    setIsPopUpVisible(props.popUpStatus);
  }, [props.popUpStatus]);

  //    const hideSurveyPopUp = () => {
  //        props.hideSurveyPopUp()
  //    }

  const skipSurveyPopUp = () => {
    props.skipSurveyPopUp();
  };
  // const handleDeleteButton = () => {
  // //    props.handleDeleteButton()
  // }
  const handleSurvey = () => {
    let obj = {};
    if (!isSurvey) if (selectedSurvey) obj.survey = selectedSurvey;
    if (selectedRole) obj.role = selectedRole;
    if (referralCourt) obj.referralCourt = referralCourt;
    props.handleSurvey(obj);
  };
  const selectedSurveyValue = async (item, index) => {
    setReferralCourt("");
    await setSelectedSurvey(item);
  };
  const selectedRoleValue = async (item, index) => {
    await setSelectedRole(item);
  };

  useEffect(() => {
    async function roleUpdated() {
      if (selectedRole.id) {
        await setIsSelectedRole(true);
      } else {
        await setIsSelectedRole(false);
      }
    }
    roleUpdated();
  }, [selectedRole]);

  const hideRoleSection = () => {
    setIsSurveySection(true);
    props.surveySectionVisible(true);
  };

  const skipRoleSection = () => {
    setSelectedRole({});
    setIsSurveySection(true);
    props.surveySectionVisible(true);
  };

  const skipCLoseSurveyPopUp = async () => {
    await setSelectedSurvey({});
    isSurvey = true;
    handleSurvey();
  };

  const handlereferralChange = async (e) => {
    await setReferralCourt(e.target.value);
  };

  return (
    <div>
      {isPopUpVisible ? (
        <div className={`popTop`}>
          <div className="popContainer popMaxWidth">
            <div className="popMaster">
              <div className="popUpMainBg fullWidth">
                {!props.IsSurvey_PopUp ? (
                  <>
                    <div className="surveyTitle rolePopUpTitle fullWidth marginBottom30">
                      {A12Y_CONST.TEXT.WHICH_OF_THESE_ROLES_ARE}
                      <span>
                        <svg
                          id={A12Y_CONST.BUTTON_ID.ROLE_POPUP_CROSS_BUTTON_ID}
                          onClick={skipSurveyPopUp}
                          className="cursorPointer"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 6.83182L15.1114 0.169434L17 2.06734L10.3886 8.7294L17 15.3915L15.1114 17.2894L8.5 10.627L1.88859 17.2894L0 15.3915L6.61141 8.7294L0 2.06734L1.88859 0.169434L8.5 6.83182Z"
                            fill="#71767A"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className={"fullWidth surveyMainList roleList"}>
                      {roleList.map((item, index) => {
                        return (
                          <>
                            <label
                              key={index}
                              className={` fullWidth marginBottom20 ${
                                item.isChecked ? "checked" : ""
                              }`}
                            >
                              <input
                                type="radio"
                                className={
                                  item.id === selectedRole.id ? "checked" : ""
                                }
                                onChange={(val) =>
                                  selectedRoleValue(item, index)
                                }
                                checked={
                                  item.id === selectedRole.id ? true : false
                                }
                                key={item.title}
                              />
                              <span>{item.title}</span>
                            </label>
                          </>
                        );
                      })}
                    </div>
                    <div className="fullWidth surveyPopUpButtonRow marginTop20 marginBottom20 textAlignRight">
                      <span className="pageCount"></span>
                      <span>
                        <span
                          onClick={skipRoleSection}
                          id={A12Y_CONST.BUTTON_ID.ROLE_POPUP_SKIP_BUTTON_ID}
                          className="skipButton"
                        >
                          {A12Y_CONST.TEXT.SKIP}
                        </span>
                        <div
                          id={A12Y_CONST.BUTTON_ID.ROLE_POPUP_NEXT_ID}
                          className={`popUpNoButton ${
                            isSelectedRole
                              ? "buttonActive"
                              : "clickDisable buttonBg"
                          }`}
                          onClick={hideRoleSection}
                        >
                          {A12Y_CONST.BUTTON_TEXT.Next}
                        </div>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="surveyTitle marginBottom30">
                      {A12Y_CONST.TEXT.HOW_DID_YOU_HEAR}
                      <span>
                        <svg
                          id={A12Y_CONST.BUTTON_ID.SURVEY_POPUP_CROSS_BUTTON_ID}
                          onClick={skipCLoseSurveyPopUp}
                          className="cursorPointer"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 6.83182L15.1114 0.169434L17 2.06734L10.3886 8.7294L17 15.3915L15.1114 17.2894L8.5 10.627L1.88859 17.2894L0 15.3915L6.61141 8.7294L0 2.06734L1.88859 0.169434L8.5 6.83182Z"
                            fill="#71767A"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className={"fullWidth surveyMainList"}>
                      {surveyList.map((item, index) => {
                        return (
                          <div className="fullWidth">
                            <label
                              key={index}
                              className={`surveyTitleList marginBottom20 ${
                                item.isChecked ? "checked" : ""
                              }`}
                            >
                              <input
                                type="radio"
                                className={
                                  item.id === selectedSurvey.id ? "checked" : ""
                                }
                                onChange={(val) =>
                                  selectedSurveyValue(item, index)
                                }
                                checked={
                                  item.id === selectedSurvey.id ? true : false
                                }
                                key={item.title}
                              />
                              <span>{item.title}</span>
                            </label>
                            {item.id === selectedSurvey.id &&
                            (selectedSurvey.title ===
                              A12Y_CONST.TEXT.REFERRAL_COURTS ||
                              selectedSurvey.title ===
                                A12Y_CONST.TEXT.REFERRAL_FROM_PROFESSIONAL) ? (
                              <div className={"width80 referralColPopUP"}>
                                <input
                                  value={referralCourt}
                                  id="referralCourt"
                                  //                                                               onBlur={validatePaidTo}
                                  onChange={handlereferralChange}
                                  //onFocus={()=>setPaidToErrorMeesage(false)}
                                  className="fullWidth inputColum24size"
                                  placeholder="Referral Code (optional)"
                                />
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                    <div className="fullWidth surveyPopUpButtonRow textAlignRight">
                      <span className="pageCount"></span>
                      <span>
                        <span
                          onClick={skipCLoseSurveyPopUp}
                          id={A12Y_CONST.BUTTON_ID.SURVEY_POPUP_SKIP_BUTTON_ID}
                          className="skipButton"
                        >
                          {A12Y_CONST.TEXT.SKIP}
                        </span>
                        <div
                          id={A12Y_CONST.BUTTON_ID.GET_STARTED_ID}
                          className=" popUpNoButton"
                          onClick={handleSurvey}
                        >
                          {A12Y_CONST.BUTTON_TEXT.GET_STARTED}
                        </div>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default PopUpWelcomeAdvocord;
