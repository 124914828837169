import React, { useEffect, useState } from "react";
import A12Y_CONST from "../common/a12yConst";
import NancyMeyers from "../../src/assets/images/NancyMeyers.jpg";
import { Link } from "react-router-dom";

const PopUpQuikConsult = (props) => {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  useEffect(() => {
    setIsPopUpVisible(props.popUpStatus);
  }, [props.popUpStatus]);

  const hidePopUp = () => {
    props.hidePopUp();
  };

  return (
    <div>
      {isPopUpVisible ? (
        <div className={`popTop`}>
          <div className="popContainer popMaxWidth">
            <div className="popMaster">
              <div className="popUpMainBg fullWidth">
                <div className="cancelIcon fullWidth"></div>
                <div className="text textAlignCenter ">
                  <img
                    width={120}
                    className="marginBottom10"
                    src={NancyMeyers}
                  />
                </div>
                <div
                  className={`areYouText areYouDmFont ${
                    props.popUpType === A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION
                      ? "fontWeight400"
                      : null
                  }`}
                >
                  {A12Y_CONST.TEXT.NEED_A_QUICK}
                </div>
                <div className={"useInfo marginTop10 maxWidth500"}>
                  {A12Y_CONST.TEXT.TAKING_CARE_OF_SOMEONE}
                  <a
                    className="linkColorText"
                    href="https://calendly.com/nmeyersadvocord/30min?month=2024-10"
                    target="_blank"
                  >
                    {A12Y_CONST.TEXT.FREE_CONSULTATION}
                  </a>
                  {A12Y_CONST.TEXT.WITH_ME}
                </div>

                <div className="fullWidth marginTop20 marginBottom20 textAlignRight">
                  <div
                    id="quickCallContiueButtonId"
                    className=" popUpNoButton"
                    onClick={hidePopUp}
                  >
                    {A12Y_CONST.BUTTON_TEXT.CONTINUE}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PopUpQuikConsult;
